import './AppPages.css';
import { useState } from 'react';
import { Link, Paper, Typography } from '@mui/material';

import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


function ShutdownNotice({ appData, handleClose }) {
    const [dismissed, setDismissed] = useState(false);
    const handleDismissal = () => {
        setDismissed(true);
        handleClose();
    }

    return (
        <Dialog
            open={!dismissed}
            onClose={handleClose}
            className="modal"
            >
            <DialogTitle className="modalHeader">
                <Box>
                    <Box>LAYING ROOTL TO REST</Box>
                    <Box>
                        <IconButton className="closeButton" onClick={handleDismissal}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>

            </DialogTitle>
            <DialogContent className='modalContent'>
                <Box>&nbsp;</Box>
                <Paper className='quickHelp' elevation={3}>
                    <Typography variant="h6">
                        Rootl’s final puzzle will be on February 2, 2025.
                    </Typography>
                    <Typography variant="body2">
                        <br />The puzzles will remain in place for now, but no promises.<br /><br />
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        For more on my current and future projects:
                        <ul>
                            <li>Follow me on <Link href="https://bsky.app/profile/fogandwhisky.bsky.social" target="_blank">Bluesky</Link>.</li>
                            <li>Check out my <Link href="https://fogandwhisky.com/" target="_blank">website</Link>.</li>
                            <li>Subscribe to my <Link href="https://fogandwhisky.com/newsletter/" target="_blank">blog.</Link></li>
                        </ul>
                    </Typography>
                </Paper>
                <div className='actions'>
                    
                    <Button variant='contained'
                        onClick={handleDismissal}>
                            Play game
                    </Button>
                </div>

                
            </DialogContent>
            </Dialog>
    );
}

export default ShutdownNotice;