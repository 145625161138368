import React, { useEffect, useState } from 'react';
import './App.css';
import Level from './Components/Game/Level';
import Help from './Components/AppPages/Help';
import Faq from './Components/AppPages/Faq';
import Score from './Components/Game/Score/Score';
import Stats from './Components/AppPages/Stats';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Toaster from './Components/Toaster/Toaster';
import manifest from './Data/manifest.json';
import { dateSub } from './Utilities/DateUtility';
import { demungeGameData, mungeGameData } from './Utilities/GameDataMunger';
import { useCookies, withCookies } from 'react-cookie';
import {rootlCookieName} from './Utilities/RootlCookieProvider';
import AppMenu from './Components/AppMenu/AppMenu';
import LevelMenu from './Components/Game/LevelMenu/LevelMenu';
import { currentGameState } from './Utilities/ScoreUtility'
import useMediaQuery from '@mui/material/useMediaQuery';
import GA4 from './Components/Analytics/GA4';
import ModalHelp from './Components/AppPages/ModalHelp';
import ShutdownNotice from './Components/AppPages/ShutdownNotice';
import GameSelector from './Components/Game/GameSelector/GameSelector';
import GameMenu from './Components/Game/GameMenu/GameMenu';
import { getFoundLetters } from './Utilities/LetterFinder';
import { Link } from '@mui/material';
import CelebrationAnimation from './Components/CelebrationAnimation/CelebrationAnimation';
import Announcement from './Components/Announcement/Announcement';

const theme = createTheme({
  palette: {
    mode: 'dark',
     primary: {
        main: '#A6B4CB'
     }, 
     secondary: {
        main: '#000'
    }, 
     success: {
      main:  '#7DA86F' 
     },
     error: {
      main:  '#A94A49' 
     },
  }
});


function App (props) {
  // This version of the gameData is dynamic and used throughout the game
  // to maintain game state
  const [gameData, setGameData] = useState(
    {
      gameId:-1,
      imgUrl: "",
      alt: "",
      link: "",
      currentLevelId: 0,
      levelData:[
        {
          answer:"",
          success:0, 
          guesses:[]
        }]
      });
  // This version of the gameData is used solely as a reference
  // to the unadulterated JSON data loaded from the server.
  const [gameIdentityData, setGameIdentityData] = useState(gameData);

  const [appData, setAppData] = useState({
    minDate: manifest.minDate,
    currentAppPage: 'game',
    allowedGuesses: manifest.allowedGuesses,
    scoreArray: manifest.scoreArray,
    warningStep: manifest.warningStep,
    dangerStep: manifest.dangerStep,
    currentDate: Math.min(dateSub(new Date(), new Date(manifest.day1)), manifest.maxDate),
    cursorDate: Math.min(dateSub(new Date(), new Date(manifest.day1)), manifest.maxDate),
    announcements: manifest.announcements,
    mode: 'normal',
    showModal: ''
  });

  const [toasterMessage, setToasterMessage] = useState("");
  const [toasterAnchorElement, setToasterAnchorElement] = useState(null);
  const [toasterBlocking, setToasterBlocking] = useState(false);
  const [gaPayload, setGaPayload] = useState(null);
  const [gameSelectorData, setGameSelectorData] = useState(false);
  const [celebrationState, setCelebrationState] = useState(0);
  
  // All of this is a hackaround for what appears to be a bug in react-cookies:
  // the state of the cookie can't be re-read within the current session using useCookies.
  // So I have to read from props.allCookies (which does get correctly updated)
  // while writing to setCookie. gameResolved ensures that writes are handled just once.
  const [, setCookie] = useCookies([rootlCookieName]);
  const cookie = props.allCookies;
  const cookieProps = {
    path: "/",
    maxAge: 60*60*24*365,  // one year cookie life
    sameSite: "lax"
  }

  // Game event handlers
  const handleLevelChange = (value) => {
    if (value > -1 && value < gameData.levelData.length) {
      setGameData(previousState => {
        return { ...previousState,
          currentLevelId: value
        }
      });
    }
  }

  const openGameSelector = () => {
    setGameSelectorData(true);
  }

  const handleGameDateChange = (newDate) => {
    // Note the use of the bitwise operator to force the string to a number
    const newDateAsNum = ~~newDate;
    setGameData(previousState => {
      return { ...previousState,
        currentLevelId: 0
      }
    });
    setAppData(previousState => {
      return { ...previousState,
        cursorDate: newDateAsNum
      }
    });
  }
  const handleMenuClick = (event, newPage) => {
    if (!isNaN(newPage)) {
      handleGameDateChange(newPage);
    } else if (newPage === "first") {
      handleGameDateChange(1);
    } else if (newPage === "last") {
      handleGameDateChange(appData.currentDate);
    } else if (newPage === "prev") {
      handleGameDateChange(appData.cursorDate - 1);
    } else if (newPage === "next") {
      handleGameDateChange(appData.cursorDate + 1);
    } else if (newPage !== null) {
      setCookie("currentAppPage", newPage, cookieProps);
      setAppData(previousState => {
        window.scrollTo(0,0);
        return {...previousState,
                currentAppPage: newPage
        }
      });
    }
  }

  const handleNewGuess = (guess, asQuit) => {
    const levelData = gameData.levelData;

    const level = levelData[gameData.currentLevelId];
    level.guesses.unshift(guess);
    const totalGuesses = level.guesses.length;

    // Special case: the player found all the letters, but not the word.
    const foundLetters = getFoundLetters(level.answer, level.guesses);

    let success = 0;
    if (asQuit) {
      success = 1;  // QUIT
      analyticsEvent({
        "event": "level_complete",
        "success": "quit",
        "guesses": totalGuesses
      });
    } else if (guess === level.answer) {
      setCelebrationState(1);
      success = 2;
      analyticsEvent({
        "event": "level_complete",
        "success": "succcess",
        "guesses": totalGuesses
      });
    } else if (foundLetters.indexOf(" ") === -1) {
      handleToasterMessage("You found all letters but not the answer! But I'm a nice guy so I'm giving it to you!", document.getElementById("input-textfield"));
      success = 2;
      analyticsEvent({
        "event": "level_complete",
        "success": "succcess",
        "guesses": totalGuesses
      });
    } else if (totalGuesses === appData.allowedGuesses) {
      success = 1; // That was the last guess
      analyticsEvent({
        "event": "level_complete",
        "success": "expended",
        "guesses": totalGuesses
      });
    }

    if (success === 0) {
      const guessesUsed = level.guesses.length || 0;
      const guessesRemaining = appData.allowedGuesses - guessesUsed;
      handleToasterMessage("Guesses remaining: " + guessesRemaining, document.getElementById("input-textfield"));

      analyticsEvent({
        "event": "made_a_guess",
        "guesses": totalGuesses
      });
    }
    
    level.success = success;

    setGameData(previousState => {
      return { ...previousState,
        levelData: levelData
      }
    });
    updateCookies();
    handleGameOver();
  }

  const handleQuit = () => {
    handleNewGuess("", true);
  }

  const handleToasterMessage = (msg, anchorElement, isBlocking) => {
    setToasterAnchorElement(anchorElement || document);
    setToasterBlocking(isBlocking ?? false)
    setToasterMessage(msg);
  }

  const analyticsEvent = (payload) => {
    setGaPayload(payload);
  }

  const updateCookies = () => {
    const updateCookieString = (gameDataString, levelId, success) => {
      // The data as read from the cookie
      let cookieData = demungeGameData(gameDataString);
      if (cookieData.gameId !== gameData.gameId) {
        // Different game. Restart string from null
        cookieData = demungeGameData(null);
      }
      cookieData.gameId = gameData.gameId;
      cookieData.levelData[levelId].answer = gameData.levelData[levelId].answer;
      cookieData.levelData[levelId].success = success;
      cookieData.levelData[levelId].guesses = gameData.levelData[levelId].guesses;
      cookieData.levelData[levelId].showHint = gameData.levelData[levelId].showHint;
      const mungedGameData = mungeGameData(cookieData);
      return mungedGameData;
    }
    // We have two cookies for storing game data, streak and hobby
    if (appData.currentDate === appData.cursorDate) {
      // For us to overwrite the STREAK cookie, we must be playing TODAY's game.
      const str = updateCookieString(cookie.streakGameData, gameData.currentLevelId, gameData.levelData[gameData.currentLevelId].success);
      setCookie("streakGameData", str, cookieProps);
    }
    else {
      // overwrite the HOBBY cookie
      const str = updateCookieString(cookie.hobbyGameData, gameData.currentLevelId, gameData.levelData[gameData.currentLevelId].success);
      setCookie("hobbyGameData", str, cookieProps);
    }
  }

  
  const handleGameOver = () => {
    const status = currentGameState(gameData.levelData, appData);
    
    if (status.isComplete) {
      // COOKIE WRANGLING
      // The game that counts is today's game (the STREAK game). All other games are just for fun.
      if (appData.cursorDate === appData.currentDate) {
        if (status.wins === status.count) {
           // Today's game was a win
            setCookie("latestWinDate", new Date().toString(), cookieProps);
            setCelebrationState(2);

            let currentStreak = Number(cookie.currentStreak) | 0;
            currentStreak ++; 
            setCookie("currentStreak", currentStreak, cookieProps);

            let maxStreak = Number(cookie.maxStreak) | 0;
            maxStreak = currentStreak > maxStreak ? currentStreak : maxStreak;
            setCookie("maxStreak", maxStreak, cookieProps);

            let totalWins = Number(cookie.totalWins) | 0; 
            totalWins ++;
            setCookie("totalWins", totalWins, cookieProps);
        } else {
          // Today's game was a loss
          setCookie("currentStreak", 0, cookieProps);
        }

        // Calculations that occur regardless of win or loss
        let numPlays = Number(cookie.numPlays) | 0;
        numPlays ++;
        setCookie("numPlays", numPlays, cookieProps);

        // Possible to improve your score, even on a loss
        let bestScore = Number(cookie.bestScore) | 0; 
        bestScore = status.score > bestScore ? status.score : bestScore;

        setCookie("bestScore", bestScore, cookieProps);

        // Calculate new average
        let averageScore = Number(cookie.averageScore) | 0;
        if (numPlays > 0) {
          averageScore = ((averageScore * (numPlays-1)) + status.score) / numPlays;
        }
        setCookie("averageScore", averageScore, cookieProps);
      }

      analyticsEvent ({
        "event": "game_complete",
        "levels_successful": status.wins,
        "levels_quit": status.quitLevels,
        "levels_expended": status.expendedLevels,
        "mode": appData.mode,
        "streak_game": appData.cursorDate === appData.currentDate
      });

      // NOW DO A TIMEOUT BEFORE TAKING US TO SEE OUR CONCLUSION
      setTimeout(() => handleMenuClick(null, "stats"), 2500);
    }
  }

  const handleShowHint = () => {
      gameData.levelData[gameData.currentLevelId].showHint = true;
      // "%" is a magic character indicating the user has taken a hint (and therefore lost a turn)
      gameData.levelData[gameData.currentLevelId].guesses.push("%");
      setGameData(previousState => {
        return { ...previousState,
          levelData: gameData.levelData
        }});
      updateCookies();
  }

  const handleModeChange = (event) => {
    const value = event.target.value;
    setAppData(previousState => {
      return { ...previousState, 
        mode: value
      }});
    setCookie("mode", value, cookieProps);
  }

  const showGame = () => {
    return appData.currentAppPage === 'game' || 
    appData.currentAppPage === 'help' ||
    appData.currentAppPage === 'stats';
  }

  const handleGameSelection = (value) => {
    // Special cheat for testing
    if (window.location.href.indexOf("localhost") > -1 && value.substring(0,2) === "!!") {
      const passValue = value.replace(/\D+/g, '');
      handleMenuClick(null, passValue);
    } else if(value && !isNaN(value) && value >= appData.minDate && value <= appData.currentDate) {
      handleMenuClick(null, value);
    }
  }
  

  // SIDE EFFECT
  // Restore app state or defaults
  useEffect(() => {
    appData.currentAppPage = cookie.currentAppPage ?? "help";
    appData.mode = cookie.mode ?? "normal";
  }, [appData, cookie]);

  const [queryConsumed, setQueryConsumed] = useState(false);
  // SIDE EFFECT
  // Load puzzle data
  // Runs at startup, and reruns every time the user selects a different puzzle
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const qId = params.get('id');
    if (!queryConsumed && !isNaN(qId) && qId > 0 && qId <= appData.currentDate && qId !== appData.cursorDate) {
      setQueryConsumed(true);
      handleGameDateChange(qId);
      return;
    }

    let fetchJson = () => {
      fetch('./Puzzles/p' + appData.cursorDate + '.json')
      .then(response => {
          return response.json();
      }).then(data => {
        setGameIdentityData(data);
      }).catch((e) => {
        console.log(e.message);
    })}
    fetchJson();
  }, [appData.cursorDate, appData.currentDate, queryConsumed, setQueryConsumed, setGameIdentityData]);

  // SIDE EFFECT
  // Take loaded identity data & merge with cookies to generate dynamic game data.
  // This reruns whenever gameData or cookies update
  // Or when the gameIdentityData gets loaded
  useEffect(() => {
    const mergeCookieDataWithJsonData = (jsonData, cookieData) => {
      if (cookieData.gameId === jsonData.gameId) {
        const levelData = jsonData.levelData;
        for (let a = 0; a < levelData.length; a++) {
          const element = levelData[a];
          element.guesses = cookieData.levelData[a].guesses;
          element.success = cookieData.levelData[a].success;
          element.showHint = cookieData.levelData[a].showHint;
          levelData[a] = element;
        }
      }
    }

    // Copy the identity object so we don't forward deep references
    const gameIdentityDataCopy = JSON.parse(JSON.stringify(gameIdentityData));

    // Read Streak and Hobby cookies.
    const streakGameData = demungeGameData(props.allCookies.streakGameData);
    const hobbyGameData = demungeGameData(props.allCookies.hobbyGameData);
    
    // If EITHER has an ID that matches the current game, merge the gameIdentityData.
    if (gameIdentityDataCopy.gameId === streakGameData.gameId) {
      mergeCookieDataWithJsonData(gameIdentityDataCopy, streakGameData);
    } else if (gameIdentityDataCopy.gameId === hobbyGameData.gameId) {
      mergeCookieDataWithJsonData(gameIdentityDataCopy, hobbyGameData);
    }

    // Ensure all answers are forced to upper case
    // Ensure all levels at least have a success value and a guesses array
    for (let a = 0; a < gameIdentityDataCopy.levelData.length; a++) {
      const element = gameIdentityDataCopy.levelData[a];
      element.answer = gameIdentityDataCopy.levelData[a].answer.toUpperCase();
      element.guesses = element.guesses ?? [];
      element.success = element.success ?? 0;
    }

    setGameData(previousState => {
      return { ...previousState,
        gameId: gameIdentityDataCopy.gameId,
        imgUrl: gameIdentityDataCopy.imgUrl,
        alt: gameIdentityDataCopy.alt,
        link: gameIdentityDataCopy.link,
        levelData: gameIdentityDataCopy.levelData,
      }
    });

  }, [props.allCookies, setGameData, gameIdentityData]);


  ///
  /// Render the DOM
  ///
  // Build level & game boards menu based on loaded data

  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className='rootl'>
    <ThemeProvider theme={theme} >
      <Toaster message={toasterMessage} 
        anchorElement={toasterAnchorElement}
        isBlocking={toasterBlocking}
        handleClearToaster={() => {
            setToasterMessage("");
            setToasterAnchorElement(null);
          }} />
      <div className='rootl-header'>
        <AppMenu appData={appData} handleMenuClick={handleMenuClick} handleToasterMessage={handleToasterMessage} analyticsEvent={analyticsEvent} />
        <h1><Link underline="none" 
          onClick={() => handleMenuClick(null, 'game')}
          href="/">
            Rootl
            <span><img src={require ('./Images/logo192.png')} alt='Rootl logo' /></span>
            </Link>
        </h1>

        <Announcement appData={appData} gameData={gameData} handleToasterMessage={handleToasterMessage} analyticsEvent={analyticsEvent} />

        <div className='rightFill'></div>
      </div>
        <h2 className="subHead">The meaning beneath the meaning</h2>
        <GameMenu appData={appData} handleMenuClick={handleMenuClick} />

      <div className={showGame() ? 'gameArea' : 'hidden' }>
          {/* This one is for small screens */}
          <div className={matchesSM ? 'levelSelectorAndScore' : 'hidden'}>
            <div className='gameId'><Link onClick={() => openGameSelector()}>Rootl #{gameData.gameId}</Link></div>
            <Score appData={appData} gameData={gameData} aria-label="Score" />
            <LevelMenu gameData={gameData} levelChangeHandler={handleLevelChange} />
          </div>
       
          {/* This one is for wide screens */}
          <div className={matchesSM ? 'hidden' : 'levelSelectorAndScore'}>
            <LevelMenu gameData={gameData} levelChangeHandler={handleLevelChange} />
            <div className='gameId'><Link onClick={() => openGameSelector()}>Rootl #{gameData.gameId}</Link></div>
            <Score appData={appData} gameData={gameData} aria-label="Score" />
          </div>
      
          <Level appData={appData} 
                key={gameData.levelData[gameData.currentLevelId].answer}
                gameData={gameData}
                cookie={cookie}
                handleNewGuess={handleNewGuess} 
                handleQuit={handleQuit}
                handleShowHint={handleShowHint}
                handleToasterMessage={handleToasterMessage}
                handleModeChange={handleModeChange}
                levelChangeHandler={handleLevelChange}
                analyticsEvent={analyticsEvent} />
      </div>

      <CelebrationAnimation
        celebrationState={celebrationState}
        handleClearCelebrationState={() => {
          setCelebrationState(0);
        }} />

      {/* The quick help page presented by default */}
      <ModalHelp appData={appData} 
        handleClose={() => {
            analyticsEvent({
              "event": "close_quick_help"
            });
            handleMenuClick(null, "game");
        }}
        handleMenuClick={() => {handleMenuClick(null, "fullHelp")}} />
      {/*  */}
      <ShutdownNotice appData={appData} 
        handleClose={() => {
            handleMenuClick(null, "game");
        }}
        handleMenuClick={() => {handleMenuClick(null, "fullHelp")}} />
      {/* The arbitrary level selector window */}
      <GameSelector appData={appData}
        gameSelectorData={gameSelectorData}
        confirmHandler={ handleGameSelection }
      closeHandler={() => {
        setGameSelectorData(false);
      }}
        handleMenuClick={() => {handleMenuClick(null, "fullHelp")}} />
      {/* The full help page */}
      <Help appData={appData} 
          gotoGameHandler={() => {handleMenuClick(null, "game")}} />

      <Faq appData={appData}
          handleMenuClick={handleMenuClick} />
      <Stats appData={appData} gameData={gameData} 
          allCookies={cookie} 
          analyticsEvent={analyticsEvent} 
          handleClose={() => {handleMenuClick(null, "game")}}
          handleToasterMessage={handleToasterMessage} />

      <div className="copyright">&copy; 2023-2024 Marc Tanenbaum</div>
      <GA4 payload={gaPayload} setPayload={setGaPayload} />
    </ThemeProvider>
    </div>
    )
  }

export default withCookies(App);